import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {
  DataGridPremium,
  GridColDef,
  useGridApiContext,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowParams,
  GridRowsProp,
  GridRowId,
  GridCallbackDetails,
  DataGridPremiumProps,
  GridToolbar,
} from '@mui/x-data-grid-premium';

import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid, styled } from '@mui/material';
import { Height } from '@mui/icons-material';
import { debug } from 'console';
import { useNavigate } from 'react-router-dom';

interface AsnSummaryDetailsProps {
  status: string | undefined;
  fromDate: string
  toDate: string
  asnNumber: string
}


function DetailPanelContent({ row: rowProp }: { row: any }) {
  const apiRef = useGridApiContext();

  const tokenValue: any = localStorage.getItem("accessToken")
  const apiUrlOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashData_orders"
  const apiUrlItemsOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetASNreceiptItems"
  const apiUrlItemsOrderItems: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetItemDetails"
  const [open, setOpen] = React.useState(false);
  const [orderItemsData, setorderItemsData] = React.useState([])
  const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([])
  const [orderItemsDataRows, setorderItemsDataRows] = React.useState([])
  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const navigate = useNavigate();
  const currCustomer = localStorage.getItem("currentOwner")
  const currWHS = localStorage.getItem("currentWHS")

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);


  React.useEffect(() => {

    createMasterData(rowProp.ASN_ReceiptNo)
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange,

    );
  }, [apiRef, handleViewportInnerSizeChange]);

  async function getOrderItemsApiData(params: any) {
    const responseOrdersItems = await getDashData_ordersItems(
      {
        "asnReceiptNo": params,
        "custCode": currCustomer,
        "whseCode": currWHS
      })
    if (responseOrdersItems) {
      setorderItemsData(responseOrdersItems?.Payload)
    }
  }

  async function getDashData_ordersItems(params: any) {
    return fetch(apiUrlItemsOrder, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }

  const createMasterData = (rowprops: any) => {
    let dataItems: Array<any> = []
    getOrderItemsApiData(rowProp.ASN_ReceiptNo)

  }


  async function getOrderItemsDetailsApiData(params: any) {
    const responseOrdersItems = await getDashData_ordersItemsDetails(
      {
        "itemNo": params,
        "custCode": currCustomer,
        "whseCode": currWHS
      })
    if (responseOrdersItems) {
      setorderItemsDataDetails(responseOrdersItems?.Payload)
    }
  }

  async function getDashData_ordersItemsDetails(params: any) {
    return fetch(apiUrlItemsOrderItems, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }
  const handleClose = () => {
    setOpen(false);
  };
  const createItemsData = (rowprops: any) => {
    let dataItems: Array<any> = []
    getOrderItemsDetailsApiData(rowprops.row.Item)
    setOpen(true)
    return orderItemsDataDetails?.map((c: any, index: any) => ({
      id: index,
      Item: c.Item,
      Description: c.Description,
      Pack: c.Pack,
      GrossWeight: c.GrossWeight
    }))
  }



  const columnsItem: GridColDef[] = [
    { field: 'LineItemNo', headerName: 'Line Item No' },
    { field: 'ReceiptNo', headerName: 'Receipt No' },
    { field: 'Item', headerName: 'Item' },
    { field: 'Pack', headerName: 'Pack' },
    { field: 'ExpectedQty', headerName: 'Expected Qty' },
    { field: 'ReceivedQty', headerName: 'Received Qty' },
    { field: 'ReceiptStatus', headerName: 'Receipt Status' },
    { field: 'ReceiptType', headerName: 'Receipt Type' },
    { field: 'ReceivedDate', headerName: 'Received Date' },
    { field: 'UOM', headerName: 'UOM' },
    { field: 'ConditionCode', headerName: 'Condition Code' },
    { field: 'CUBE', headerName: 'CUBE' },   
    { field: 'GrossWeight', headerName: 'Gross Weight' },
    { field: 'NetWeight', headerName: 'Net Weight' },
    { field: 'QCRequired', headerName: 'QC Required' },
    { field: 'QCStatus', headerName: 'QC Status' },

  ];
  const rowItems: GridRowsProp = orderItemsData.map((c: any, index) => ({
    id: index,
    CUBE: c.CUBE,
    ConditionCode: c.ConditionCode,
    ExpectedQty: c.ExpectedQty,
    ReceivedQty : c.ReceivedQty,
    GrossWeight: c.GrossWeight,
    Item: c.Item,
    LineItemNo: c.LineItemNo,
    NetWeight: c.NetWeight,
    Owner: c.Owner,
    Pack: c.Pack,
    QCRequired: c.QCRequired,
    QCStatus: c.QCStatus,
    ReceiptNo: c.ReceiptNo,
    ReceiptStatus: c.ReceiptStatus,
    ReceiptType: c.ReceiptType,
    ReceivedDate: c.ReceivedDate,
    ToLocation: c.ToLocation,
    UOM: c.UOM,
    Warehouse: c.Warehouse

  }))

  const columnsItemDetails: GridColDef[] = [

    { field: 'Item', headerName: 'Item' },
    { field: 'Description', headerName: 'Description' },
    { field: 'Pack', headerName: 'Pack' },
    { field: 'GrossWeight', headerName: 'Gross Weight' },

    { field: 'NetWeight', headerName: 'Net Weight' },
    { field: 'Cube', headerName: 'Cube' },
    { field: 'Class', headerName: 'Class' },
    { field: 'ItemGroup1', headerName: 'Item Group1' },
    { field: 'PICKCODE', headerName: 'PICK CODE' },
    { field: 'CartonGroup', headerName: 'Carton Group' },

    { field: 'PUTCODE', headerName: 'PUT CODE' },
    { field: 'PutAwayLocation', headerName: 'Put Away Location' },
    { field: 'PutAwayZone', headerName: 'Put Away Zone' },
    { field: 'DateItemLastCounted', headerName: 'Date Item Last Counted' },
    { field: 'InboundQCLocation', headerName: 'Inbound QC Location' },
    { field: 'RotateBy', headerName: 'Rotate By' },

    { field: 'RFDefaultReceivingPack', headerName: 'RF Default Receiving Pack' },
    { field: 'RFDefaultReceivingUOM', headerName: 'RF Default Receiving UOM' },
    { field: 'ReturnLocation', headerName: 'Return Location' },
    { field: 'InboundQCLoc', headerName: 'Inbound QC Loc' },
    { field: 'ReceiptValidation', headerName: 'Receipt Validation' },
    { field: 'OutboundQCLoc', headerName: 'Outbound QC Loc' },


  ];

  const rowItemsDetails: GridRowsProp = orderItemsDataDetails.map((c: any, index) => ({
    id: index,
    Owner: c.Owner,
    Warehouse: c.Warehouse,
    Item: c.Item,
    Description: c.Description,
    Pack: c.Pack,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    Cube: c.Cube,
    Class: c.Class,
    ItemGroup1: c.ItemGroup1,
    PICKCODE: c.PICKCODE,
    CartonGroup: c.CartonGroup,
    PUTCODE: c.PUTCODE,
    PutAwayLocation: c.PutAwayLocation,
    PutAwayZone: c.PutAwayZone,
    DateItemLastCounted: c.DateItemLastCounted,
    InboundQCLocation: c.InboundQCLocation,
    RotateBy: c.RotateBy,
    RFDefaultReceivingPack: c.RFDefaultReceivingPack,
    RFDefaultReceivingUOM: c.RFDefaultReceivingUOM,
    ReturnLocation: c.ReturnLocation,
    InboundQCLoc: c.InboundQCLoc,
    ReceiptValidation: c.ReceiptValidation,
    OutboundQCLoc: c.OutboundQCLoc
  }))

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row.hierarchy;
  return (
    <><Stack
      sx={{
        py: 2,
        height: '100%',
        boxSizing: 'border-box',
        position: 'sticky',
        left: 0,
        width,
      }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1, background: "#f6f1f4" }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography className='colordivSumHead' fontWeight="bold" variant="h6">{`Details for ASN Receipt no: #${rowProp.ASN_ReceiptNo}`}</Typography>
          <Box sx={{ width: '100%', height: 300 }}>
            <DataGridPremium
              density="compact"
              slots={{ toolbar: GridToolbar }}
              rowSelection={true}
              onRowClick={createItemsData}
              columns={columnsItem}
              rows={rowItems}

              //  rows={createMasterData(rowProp)}
              sx={{ flex: 1 }}
              hideFooter /></Box>
        </Stack>
      </Paper>


    </Stack>

      <Box
        sx={{ width: '100%', height: 800 }}>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={
            {
              height: '100%', width: '100%', alignItems: 'center'
            }}
        >
          <DialogTitle className='colordivSumHead font-bold' id="form-dialog-title">Item Properties:</DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <Box sx={{ width: '100%', height: 150 }}>
              <DataGridPremium columns={columnsItemDetails}
                rows={rowItemsDetails}
                slots={{ toolbar: GridToolbar }}
                density="compact"
                hideFooter
              />   </Box>

          </DialogContent>
        </Dialog>
      </Box></>

  );
}

const columns = [
  { field: 'ASN_ReceiptNo', headerClassName: "headingbg-color", headerName: 'ASN Receipt No' },
  { field: 'ReceiptType', headerClassName: "headingbg-color", headerName: 'Receipt Type' },
  { field: 'ReceiptDate', headerClassName: "headingbg-color", headerName: 'Receipt Date' },
  { field: 'totExpectedQty', headerClassName: "headingbg-color", headerName: 'Total Expected Qty', width: 200 },
  { field: 'totReceivedQty', headerClassName: "headingbg-color", headerName: 'Total Received Qty', width: 200 },
  { field: 'totDamagedQty', headerClassName: "headingbg-color", headerName: 'Total Damaged Qty', width: 200 },

  { field: 'ReceiptStatus', headerClassName: "headingbg-color", headerName: 'Receipt Status' },
  { field: 'ExpectedReceiptDate', headerClassName: "headingbg-color", width: 150, headerName: 'Expected Arrival Date' },
  { field: 'WarehouseReference', headerClassName: "headingbg-color", width: 150, headerName: 'Warehouse Reference' },
  { field: 'DateCreated', headerClassName: "headingbg-color", width: 150, headerName: 'Date Created' },

  { field: 'ExternalReceiptNo', headerClassName: "headingbg-color", width: 150, headerName: 'External Receipt No' },
  { field: 'CarrierReference', headerClassName: "headingbg-color", width: 150, headerName: 'Carrier Reference' },
  { field: 'ClosedDate', headerClassName: "headingbg-color", width: 150, headerName: 'Closed Date' },
  { field: 'TransportationStatus', headerClassName: "headingbg-color", width: 150, headerName: 'Transportation Status' },
  { field: 'TrailerNumber', headerClassName: "headingbg-color", width: 150, headerName: 'Trailer Number' },


]

const AsnGridRowGroup: React.FC<AsnSummaryDetailsProps> = (props) => {

  const [ansRecData, setansRecData] = React.useState(new Array)
  const [orderItemsData, setorderItemsData] = React.useState([])
  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    [],
  );

  const getDetailPanelHeight = React.useCallback(() => 200, []);

  const navigate = useNavigate();
  const tokenValue: any = localStorage.getItem("accessToken")
  const apiUrlasn: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashData_ASNReceipts"
  const apiUrlItemsOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetASNreceiptItems"

  const currCustomer = localStorage.getItem("currentOwner")
  const currWHS = localStorage.getItem("currentWHS")

  React.useEffect(() => {
    try {
      getOrdersApiData("")
    }
    catch (e) {
      //log error
    }
  },
    [props]);
  async function getOrdersApiData(params: any) {
    const responseAsn = await getDashData_asn(
      {       
        "receiptType": "all",
        "receiptStatus": props.status,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "custCode": currCustomer,
        "whseCode": currWHS
      }
    )

    if (responseAsn) {           
      if (props.asnNumber) {       
       const  filteredRecords =  responseAsn.Payload?.find((x: any) => x.ASN_ReceiptNo == props.asnNumber);
        if(filteredRecords)
            {
              const newArrayList = new Array(filteredRecords)
              setansRecData(newArrayList)
            }        
      }
      else {
        setansRecData(responseAsn.Payload)
      }
    }
  }

  async function getDashData_asn(params: any) {

    return fetch(apiUrlasn, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }
  async function getDashData_ordersItems(params: any) {
    return fetch(apiUrlItemsOrder, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }

  async function getOrderItemsApiData(params: any) {
    const responseOrdersItems = await getDashData_ordersItems(
      {
        "asnReceiptNo": params,
        "custCode": currCustomer,
        "whseCode": currWHS
      })
    return responseOrdersItems
  }

  React.useEffect(() => {
    try {
      getOrdersApiData("")
    }
    catch (e) {
      //log error
    }
  },
    []);


  const rows: GridRowsProp = ansRecData.map((c: any, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    ASN_ReceiptNo: c.ASN_ReceiptNo,
    ReceiptType: c.ReceiptType,
    ReceiptDate: c.ReceiptDate,
    totExpectedQty: c.totExpectedQty,
    totReceivedQty: c.totReceivedQty,
    totDamagedQty: c.totDamagedQty,
    ReceiptStatus: c.ReceiptStatus,
    ExpectedReceiptDate: c.ExpectedReceiptDate,
    WarehouseReference: c.WarehouseReference,
    DateCreated: c.DateCreated,
    ExternalReceiptNo: c.ExternalReceiptNo,
    CarrierReference: c.CarrierReference,
    ClosedDate: c.ClosedDate,
    TransportationStatus: c.TransportationStatus,
    TrailerNumber: c.TrailerNumber

  }));

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[], details: GridCallbackDetails) => {

      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      );

    },
    [],
  );

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
    GridRowId[]
  >([]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    background: "transparent",
  }));

  return (
    <Box sx={{ width: '100%', height: 560, marginBottom: '10px' }}>
      <div className='mt-16'></div>
      <Grid className='mt-8' >
        <Grid item sm={12}>
          <h6 className='colordivSumHead'>ASN Details:</h6>
          <Item>
            <div style={{ height: 550, width: '100%' }}>
              <DataGridPremium
                columns={columns}
                rows={rows}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'ReceiptDate', sort: 'desc' }],
                  },
                }}
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  }
                }}
                pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                //  onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                // detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                sx={{
                  '& .MuiDataGrid-detailPanel': {
                    overflow: 'visible',
                  },
                }}
              />
            </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AsnGridRowGroup
