import React, { useEffect, useState } from "react";
import {
  DataGridPremium,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import Grid from "@mui/material/Grid";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { blueGrey } from "@mui/material/colors";
import OrderSummaryDetails from "./OrderSummaryDetails";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, SelectChangeEvent, useMediaQuery } from "@mui/material";
import DataGridOrders from "./DataGridOrders";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MonthCalendar } from "@mui/x-date-pickers";
import { current } from "@reduxjs/toolkit";
import { setYear } from "date-fns";
import DatePickerValue from "./dateControl";
import { FieldChangeHandlerContext } from "@mui/x-date-pickers/internals";
import GridRowGrouping from "./GridRowGrouping";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { DefaultizedPieValueType, PieItemIdentifier } from "@mui/x-charts";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

export default function OrderSummaryChart() {
  // const [selectedStatus, setSelectedStatus] = React.useState('');
  // const [selectedDFrom, setselectedDFrom] = React.useState('2023-01-01');
  // const [selectedDTo, setselectedDTo] = React.useState('2025-01-01');
  const [selectedMFrom, setselectedMFrom] = React.useState("");
  const [selectedMTo, setselectedMTo] = React.useState("");
  const [selectedYFrom, setselectedYFrom] = React.useState("");
  const [selectedYTo, setselectedYTo] = React.useState("");
  const [selectedFrom, setselectedFrom] = React.useState("2023-01-01");
  const [selectedTo, setselectedTo] = React.useState("2024-12-30");
  const [statusData, setStatusData] = React.useState([]);

  const [valueChanged, setvalueChanged] = React.useState(false);

  let dataItems: Array<any> = [];

  const [identifier, setIdentifier] = React.useState<null | PieItemIdentifier>(
    null
  );
  const [id, setId] = React.useState<undefined | string | number>(undefined);

  const [selectedStatus, setSelectedStatus] = React.useState<any
  >(undefined);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const [isRowClicked, setIsRowClicked] = React.useState(false);

  const [yearValue, setyearValue] = React.useState<Dayjs | null>(dayjs());

  const [monthValue, setMonthValue] = React.useState<Dayjs | null>(dayjs());

  const [dailyValue, setDailyValue] = React.useState<Dayjs | null>(dayjs());

  const currCustomer = localStorage.getItem("currentOwner");
  const currWHS = localStorage.getItem("currentWHS");




  const tokenValue: any = localStorage.getItem("accessToken");
  const apiUrlStatus: any =
    "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashCounters";

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const isMobile = useMediaQuery("(max-width: 450px)");
  //   const isTablet = useMediaQuery("(min-width:451px) and (max-width:850px)");

  const navigate = useNavigate();

  async function getOrdersApiData(param: any) {
    try {
      const response = await getDashData_status(param);
      setStatusData(response.Payload);
    } catch (e) {
      //log error
    }
  }

  async function getDashData_status(param: any) {
    return fetch(apiUrlStatus, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${tokenValue}`,
      },
      body: JSON.stringify(param),
    })
      .then((data) => data.json())
      .catch((error) => {
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  useEffect(() => {
    try {
     
      
      if (localStorage.getItem("currentWHSName") != null && localStorage.getItem("currentWHSName") != "") {
       // window.location.reload()
      }
      let today: any = new Date();
      let dd: any = today.getDate();
      let mm: any = today.getMonth() + 1;
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;

      const yearlydatefrom = yyyy + "-" + "01" + "-" + "01";
      const yearlyydateto = yyyy + "-" + "12" + "-" + "30";
     

      const labelStart = start.format("YYYY-MM-DD");
      const labelEnd = end.format("YYYY-MM-DD");

      setselectedFrom(labelStart);
      setselectedTo(labelEnd);

      const dailyStatus = {
        fromDate: labelStart,
        toDate: end,
        custCode: currCustomer,
        whseCode: currWHS,
      };
    
      if (currCustomer) {
        getOrdersApiData(dailyStatus);
      }
    } catch (e) {

      console.log(e)
      //log error
    }
  }, [localStorage]);

  const myMaxDate = new Date("2029");

  const size = {
    width: 400,
    height: 200,
  };

  //let dataItems: Array<any> = []
  const dataValuesCnt = statusData?.map((s: any, index) => {
    return {
      label: s.orderStatus,
      value: s.cnt,
    };
  });

  const dataValuesCuft = statusData?.map((s: any, index) => {
    return {
      label: s.orderStatus,
      value: s.CUFt,
    };
  });
  const dataValuesPieces = statusData?.map((s: any, index) => {
    return {
      label: s.orderStatus,
      value: s.Pieces,
    };
  });

  const handleClick = (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    itemIdentifier: PieItemIdentifier,
    item: DefaultizedPieValueType
  ) => {
    
    setSelectedStatus(item.label);
    setIsRowClicked(true);
    setId(item.id);
    setIdentifier(itemIdentifier);
  };

  const palette = [
    "#FF9F9F",
    " #99BC85",
    "#D2E0FB",
    "#D4E7C5",
    "#D0BFFF",
    "#D37676",
    "#BFD8AF",
    "#B7CADB",
    "#7286D3",
    "#D7E4C0",
  ];

  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const { start, end } = state;

  const handleCallback = (start: any, end: any) => {
    setState({ start, end });
    const labelStart = start.format("YYYY-MM-DD");
    const labelEnd = end.format("YYYY-MM-DD");
   
    setselectedFrom(labelStart);
    setselectedTo(labelEnd);
    const dailyStatus = {
      fromDate: labelStart,
      toDate: labelEnd,
      custCode: currCustomer,
      whseCode: currWHS,
    };
    if (currCustomer) {
      getOrdersApiData(dailyStatus);
    }
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");

  const handleOpenInNewTab = () => {
    window.open("http://localhost:3000/reportBillOfLanding", "_blank");
  };

  return (
    <div>
      {/* <input className="btn btn-primary ml-2 mb-1" value="Sign PDF" type="button" onClick={handleOpenInNewTab}></input> */}
      <div className="text-center pageHeading">Order Summary Details</div>

      <p className="colordivSumHead">Select a date to view details:</p>
      <span className=""> </span>

      <Box sx={{ flexGrow: 1 }}>
        <DateRangePicker
          initialSettings={{
            startDate: start.toDate(),
            endDate: end.toDate(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, "days").toDate(),
                moment().subtract(1, "days").toDate(),
              ],
              "Last 7 Days": [
                moment().subtract(6, "days").toDate(),
                moment().toDate(),
              ],
              "Last 30 Days": [
                moment().subtract(29, "days").toDate(),
                moment().toDate(),
              ],
              "This Month": [
                moment().startOf("month").toDate(),
                moment().endOf("month").toDate(),
              ],
              "Last Month": [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
              ],
            },
          }}
          onCallback={handleCallback}
        >
          <div
            id="reportrange"
            className="col-4"
            style={{
              // background: "#fff",
              cursor: "pointer",
              padding: "8px 10px",
              border: "1.5px solid #ccc",
              width: "220px",
              fontSize: "14px",
              borderRadius: '4px'
            }}
          >
            <i className="fa fa-calendar"></i>&nbsp;
            <span>{label}</span> <i className="fa fa-caret-down"></i>
          </div>
        </DateRangePicker>
        {statusData.length == 0 ? (
          <div className="font-bold">
            <h6 className="text-center">No data available</h6>
          </div>
        ) : (
          <Grid className="mt-8" container spacing={2} >
            <Grid item xs="auto">
              <h6 className="font-bold mt-8">Count</h6>
              <Item style={{
                boxShadow: "none",
                border: "1px solid #BBB8B1",
                borderRadius: "25px",
                background: "#f1f1f1",
              }}>
                <PieChart
                  className="text-center"
                  colors={palette}
                  series={[
                    {
                      // arcLabel: (item) => `${item.value}`,
                      data: dataValuesCnt,
                      innerRadius: 30,
                      outerRadius: 100,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: -180,
                      endAngle: 180,
                      cx: 120,
                      cy: 110,
                    },
                  ]}
                  onItemClick={handleClick}
                  width={250}
                  height={220}
                  //margin={{left:100 }}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
              </Item>{" "}
            </Grid>
            <Grid item xs="auto">
              <h6 className="font-bold mt-8">CUFt</h6>
              <Item style={{
                boxShadow: "none",
                border: "1px solid #BBB8B1",
                borderRadius: "25px",
                background: "#f1f1f1",
              }}>
                <PieChart
                  colors={palette}
                  series={[
                    {
                      //  arcLabel: (item) => `${item.value}`,
                      data: dataValuesCuft,
                      innerRadius: 30,
                      outerRadius: 100,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: -180,
                      endAngle: 180,
                      cx: 120,
                      cy: 110,
                    },
                  ]}
                  onItemClick={handleClick}
                  width={250}
                  height={220}
                  //  margin={{ right: 100 }}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs="auto">
              <h6 className="font-bold mt-8">Pieces</h6>
              <Item style={{
                boxShadow: "none",
                border: "1px solid #BBB8B1",
                borderRadius: "25px",
                background: "#f1f1f1",
              }}>
                <PieChart
                  colors={palette}
                  series={[
                    {
                      // arcLabel: (item) => `${item.value}`,
                      data: dataValuesPieces,
                      innerRadius: 30,
                      outerRadius: 100,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: -180,
                      endAngle: 180,
                      cx: 120,
                      cy: 110,
                    },
                  ]}
                  onItemClick={handleClick}
                  width={isMobile ? 250 : 375}
                  height={isMobile ? 350 : 220}
                  margin={{ right: 150 }}
                  slotProps={{
                    legend: {
                      direction: isMobile ? 'row' : 'column',
                      position: {
                        horizontal: isMobile ? 'middle' : 'right',
                        vertical: isMobile ? 'bottom' : 'middle',
                      },
                    },
                  }}
                />
              </Item>
            </Grid>
          </Grid>
        )}
      </Box>
      {isRowClicked ? (
        <div className="mb-4 mt-8">
          <GridRowGrouping
            status={selectedStatus}
            fromDate={selectedFrom}
            toDate={selectedTo}
            orderNumber=""
          ></GridRowGrouping>
        </div>
      ) : (
        <div className="mt-8">
          <GridRowGrouping
            status={"All"}
            fromDate={selectedFrom}
            toDate={selectedTo}
            orderNumber=""
          ></GridRowGrouping>
        </div>
      )}
    </div>
  );
}
