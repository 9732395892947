import React, { useEffect, useRef, useState } from 'react';
import { DataGridPremium, GridActionsCellItem, GridCellSelectionModel, GridColDef, GridRowId, GridRowParams, GridRowsProp, GridToolbar, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, IconButton, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { styled } from '@mui/material/styles';
import { FileDownload } from '@mui/icons-material';
import { isAction } from '@reduxjs/toolkit';
import SignedPDF from './SignedPDF';
import CloseIcon from '@mui/icons-material/Close';
import { debug } from 'console';
import { useNavigate } from 'react-router-dom';
import SaveSignedPDF from './SaveSignedPDF';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument } from 'pdf-lib';
import CheckIcon from '@mui/icons-material/Check';



export default function BOLUploadFile() {
    const [dataSaved, setDataSaved] = useState(false);
    const [mbolFile, setmBolFile] = useState(null);
    const [fileBols, setFileBols] = useState(null);
    const [formDialog, setFormDialog] = useState(false);

    const fileName : any = null
    const [uploadedFilename, setFile] = useState(fileName);
    const [orderNo, setOrderNo] = React.useState('');
    const [bolNo, setBolNo] = React.useState('');
    const [mBolNo, setMbolNo] = React.useState('');
    const [statusData, setStatusData] = React.useState([])
    const signatureCanvasRef = useRef<SignatureCanvas>(null);
    const [isSigned, setIsSigned] = React.useState(0);
    const [signedFileName, setsignedFileName] = React.useState('');

    const [open, setOpen] = React.useState(false);

    const [rowClicked, setrowClicked] = React.useState(false);

    const [gridValidRowModel, setGridValidRowModel] =
        React.useState<GridValidRowModel>({});

    let cellClicked = false
    const [balanceData, setBalanceData] = React.useState([])
    const [openPopUp, setOpenPopUp] = React.useState(false)
    const currCustomer = localStorage.getItem("currentOwner")
    const currWHS = localStorage.getItem("currentWHS")

    const [orderDataBillShipTo, setOrderDataBillShipTo] = React.useState([])
    const tokenValue: any = localStorage.getItem("accessToken")
    const apiUrlUploadFile: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/UploadSignedBOLFile"
    const apiUploadUnSignedBOLFile: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/UploadUnSignedBOLFile"
    const apiUrlBal: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getRprt_billOfLadingDtls"
    const apiUrlGetBol: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetBOLfileToSign"
    const apiUrlBillToShipTo: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetOrderBillToShipToDtls"
    const apiUploadMultiUnSignedBOLFile: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/UploadUnSignedBOLMBOLfile";

    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const [cellSelectionModel, setCellSelectionModel] =
        React.useState<GridCellSelectionModel>({});

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start: any, end: any) => {

        setState({ start, end });
        const labelStart =
            start.format('YYYY-MM-DD')
        const labelEnd =
            end.format('YYYY-MM-DD')

        getBalApiData(labelStart, labelEnd)

    };
    const label =
        start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');


    const handleFileChange = async (event:any) => {
        setFile(event.target.files[0]);
        debugger
        const responseFile = await UploadUnSignedMbolFile(event.target.files[0])

        if (responseFile) {
            var msg = responseFile.Message != null ? responseFile.Message : (responseFile.errors != null ? responseFile.errors[""][0] : responseFile.title);

            alert(msg);
        }
       
    };

    const handleUpload = async () => {
        const responseFile = await UploadSignedMbolFile(
            {
                "bolNumber": bolNo                
            })

        if (responseFile) {
            var msg = responseFile.Message != null ? responseFile.Message : (responseFile.errors != null ? responseFile.errors[""][0] : responseFile.title);
            alert(msg);
        }
    };

    async function getBalApiData(frmDate: string, toDate: string) {
        const responseBal = await getDashData_balance(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "carrier": "all",
                "status": "all"
            })
        if (responseBal) {
            setBalanceData(responseBal.Payload)

        }
    }

    async function UploadSignedMbolFile(params: any) {

        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('bolNumber', params.bolNumber);
        formData.append('mBolNumber', params.mBolNumber);
        return fetch(apiUrlUploadFile, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenValue}`
            },
            body: formData,
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    async function getDashData_balance(params: any) {
        return fetch(apiUrlBal, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    async function getBOlApiData(bol: string) {
        const responseBal = await getDashData_BOL(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "bolNumber": bol,
                "mBolNumber": ""
            })
        if (responseBal) {
            setsignedFileName("https://igdstg.igdistribution.net/igdcpapi/" + responseBal.Payload)
            //  setOpenPopUp(true)
            setOpen(true)
        }
    }

    async function getDashData_BOL(params: any) {
        return fetch(apiUrlGetBol, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    useEffect(() => {
        try {
            const startdate =
                start.format('YYYY-MM-DD')
            const endDate =
                end.format('YYYY-MM-DD')


            getBalApiData(startdate, endDate)
        }
        catch (e) {
            //log error
        }
    },
        []);


    async function UploadUnSignedMbolFile(params: any) {
        debugger
        const currentCust: any = currCustomer
        const currentWHS: any = currWHS

        const formData = new FormData();
        formData.append("custCode", currentCust)
        formData.append("whseCode", currentWHS)
        formData.append('file',params);
        formData.append('bolNumber', bolNo);
        formData.append('mBolNumber', bolNo);
        return fetch(apiUploadUnSignedBOLFile, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenValue}`
            },
            body: formData,
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }


    const columns: GridColDef[] = [

        { field: 'OrderNumber', width: 150, headerClassName: "headingbg-color", headerName: 'Order Number' },
        {
            field: 'BLNumber',
            width: 180,
            renderCell: (params) => {
                if(params.row.signedBOLFile || params.row.sourceBOLFile) {
                    return <Link href="#">{params.value!.toString()}</Link>
                } else {
                    return params.value!.toString()
                }
                
            },
            headerClassName: "headingbg-color", 
            headerName: 'BL Number'
        },
        {
            field: 'UploadMultiFile',
            renderCell: (params) => (<Button
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 0 , fontSize:"11px"}}
                //onClick={() => setFormDialog(true)}
              >
                Upload Files
              </Button>),
            width: 120, headerClassName: "headingbg-color", headerName: 'Upload File'
        },
        // { field: 'BLNumber', headerClassName: "headingbg-color", width: 180, headerName: 'BL Number' },
        // { field: 'BLStatus', headerClassName: "headingbg-color", headerName: 'BL Status' },
        { field: 'OrderType', headerClassName: "headingbg-color", width: 200, headerName: 'Order Type' },

        { field: 'CarrierCode', width: 200, headerClassName: "headingbg-color", headerName: 'Carrier SCAC' },
        { field: 'CarrierName', width: 200, headerClassName: "headingbg-color", headerName: 'Carrier Name' },
        // { field: 'FreightPaymentType', headerClassName: "headingbg-color", headerName: 'Freight Payment Type' },
        // { field: 'sourceBOLFile', width: 200, headerClassName: "headingbg-color", headerName: 'source BOL File' },

        { field: 'Tracking_PROnumber', width: 150, headerClassName: "headingbg-color", headerName: 'Tracking PROnumber' },
        // {
        //     field: 'UploadFile',
        //     renderCell: (params) => (
        //         <Link href="#">
        //             <input type="file" accept=".pdf" onChange={handleFileChange} />
        //             Upload File</Link>),
        //     width: 280, headerClassName: "headingbg-color", headerName: 'Upload File'
        // },

        
       
    ]

    const rows: GridRowsProp = balanceData.map((c: any, index) => ({
        id: index,
        OrderNumber: c.OrderNumber,
        BLNumber: c.BLNumber,
        BLStatus: c.BLStatus,
        OrderType: c.OrderType,
        BillTo: c.BillTo,
        ShipTo: c.ShipTo,
        CarrierCode: c.CarrierCode,
        CarrierName: c.CarrierName,
        FreightPaymentType: c.FreightPaymentType,
        Tracking_PROnumber: c.Tracking_PROnumber,
        sourceBOLFile : c.sourceBOLFile,
        SealNumber: c.SealNumber,
        isBOLSigned: c.isBOLSigned,
        signedBOLFile: c.signedBOLFile,
        MBOLNumber: c.MBOLNumber
    }));


    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    const handleClose = () => {
        setOpen(false);
        setrowClicked(false)
    };

    const handleCloseDetails = () => {
        setOpenPopUp(false);
        setrowClicked(false)
    };


    const handlerRowClick = (rowprops: any, cellprops: any) => {
            setrowClicked(true)
            //setOpenPopUp(false)
    }

    async function getBillToShipToDetails(params: any) {
        const responseOrders = await getBillToShipTo_Details(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "orderNo": params
            })

        if (responseOrders) {
            setOrderDataBillShipTo(responseOrders.Payload)
        }
    }

    async function getBillToShipTo_Details(params: any) {
        return fetch(apiUrlBillToShipTo, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                navigate("/login")
            })
    }

    const handleCellSelectionModelChange = React.useCallback(
        (newModel: GridCellSelectionModel) => {

            Object.entries(newModel).forEach(([id, fields]) => {

                if (fields.BillTo || fields.ShipTo) {
                    setOpen(false)
                    setsignedFileName("")
                    const updatedRow = apiRef.current.getRow(id);
                    getBillToShipToDetails(updatedRow.OrderNumber)
                    setOpenPopUp(true)
                    cellClicked = true
                    setrowClicked(false)
                    setGridValidRowModel(updatedRow)
                } else if(fields.UploadMultiFile) {
                    const updatedRow = apiRef.current.getRow(id);
                    //setOrderNo(updatedRow.OrderNumber)
                    setBolNo(updatedRow.BLNumber)
                    setMbolNo(updatedRow.MBOLNumber)
                    setIsSigned(updatedRow.isBOLSigned)
                    const filepath = "https://igdstg.igdistribution.net/igdcpapi/"
                    setsignedFileName(filepath + updatedRow.signedBOLFile);
                    setFormDialog(true);
                } else if(fields.BLNumber) {
                    const updatedRow = apiRef.current.getRow(id);
                    setOrderNo(updatedRow.OrderNumber)
                    setIsSigned(updatedRow.isBOLSigned)
                    const filepath = "https://igdstg.igdistribution.net/igdcpapi/"
                    setsignedFileName(filepath + updatedRow.sourceBOLFile)
                    if(updatedRow.sourceBOLFile) {
                        setsignedFileName(filepath + updatedRow.sourceBOLFile)
                        setOpen(true)
                    }
                    
                    setGridValidRowModel(updatedRow);
                }
                else {
                    setrowClicked(true)
                    cellClicked = false
                }
            })

            // setCellSelectionModel(newModel);
        },
        [],
    );

    const handleClearSignature = () => {
        signatureCanvasRef.current?.clear();
    };

    const handleSavePDF = async () => {

        if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
            alert('Please provide a signature.');
            return;
        }
        const signatureImage = signatureCanvasRef.current.toDataURL();
        var pdfToSign = signedFileName

        const existingPdfBytes = await fetch(pdfToSign).then(res => res.arrayBuffer());
        const bytes = new Uint8Array(existingPdfBytes);
        const pdfDoc = await PDFDocument.load(bytes);

        const pages = await Promise.all(
            Array.from(Array(pdfDoc.getPageCount()).keys()).map(async (pageIndex) => {
                const page = await pdfDoc.getPage(pageIndex);
                const pngImage = await pdfDoc.embedPng(signatureImage);
                const pngDims = pngImage.scale(0.3); // Adjust scale if needed
                page.drawImage(pngImage, {
                    x: 380, // Adjust x and y coordinates to position the signature
                    y: 220,
                    width: pngDims.width,
                    height: pngDims.height,
                });
            })
        );

        const modifiedPdfBytes = await pdfDoc.save();
        const file = new File([modifiedPdfBytes], "signedPDF.pdf", { type: 'application/pdf' });

        uploadSignedFile(file)

    };

    async function uploadSignedFile(file: any) {

        const responseFile = await UploadSignedMbolFile(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "bolNumber": bolNo,
                "mBolNumber": "",
                "file": file
            })

        if (responseFile) {
            var msg = responseFile.Message != null ? responseFile.Message : (responseFile.errors != null ? responseFile.errors[""][0] : responseFile.title);
            alert(msg);
            setOpen(false)
        }
    }

    const handleBolFiles = (event:any) => {
        setFileBols(event.currentTarget.files)
    }

    const handleMbolFile = (event:any) => {
        setmBolFile(event.currentTarget.files[0])
    }

    async function UploadMultipleUnSignedMbolFile(fileMbol:any, fileBols:any) {
        const currentCust: any = currCustomer;
        const currentWHS: any = currWHS;

        const formData = new FormData();
        formData.append("custCode", currentCust)
        formData.append("whseCode", currentWHS)
        formData.append('fileMbol', fileMbol);

        for (let index = 0; index < fileBols.length; index++) {
            formData.append('fileBols', fileBols[index]);    
        }
        
        formData.append('bolNumber', bolNo);
        formData.append('mBolNumber', mBolNo);
        return fetch(apiUploadMultiUnSignedBOLFile, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenValue}`
            },
            body: formData,
        }).then((data) => {
            if (data.ok) {
                return data.json();
            }
            return Promise.reject(data);
            //data.json()
        })
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    const handleUploadMultiFiles = async(e:any) => {
        e.preventDefault()
        const responseFile = await UploadMultipleUnSignedMbolFile(mbolFile, fileBols)

        if (responseFile) {
            var msg = responseFile.Message != null ? responseFile.Message : (responseFile.errors != null ? responseFile.errors[""][0] : responseFile.title);

            setDataSaved(true);

            window.setTimeout(() => {
                setDataSaved(false);
                setFormDialog(false);
                window.location.reload();
            }, 1500)
        }
    }


    return (

        <div className='ml-2' >
            <div className='mt-8 mb-6 text-center pageHeading'>BOL Upload Files </div>
            <div className='pb-2' style={{ width: '30%' }}>
            </div>

            <Box sx={{ width: '100%', height: 650, padding: '0 5%' }}>
                <DataGridPremium
                    slots={{ toolbar: GridToolbar }}
                    apiRef={apiRef}
                    cellSelectionModel={cellSelectionModel}
                    onCellSelectionModelChange={handleCellSelectionModelChange}
                    cellSelection={true}
                    onRowClick={handlerRowClick}

                    columns={columns}
                    rows={rows}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        }
                    }}
                ></DataGridPremium>
            </Box>

            {open && (
                // <Box sx={{ width: '100%', height: '800px' }}>

                    <Dialog

                        id='signDialog'
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        style={
                            {
                                height: '100%', width: '100%', alignItems: 'left'
                            }}
                    >
                        <DialogTitle className='colordivSumHead font-bold' id="form-dialog-title">Order No: {orderNo}</DialogTitle>
                        <DialogContent>
                            <SignedPDF
                                fileName={signedFileName}
                                action={handleClose}
                                isSigned={isSigned}
                                showSign={false}
                            ></SignedPDF>
                        </DialogContent>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Dialog>
                // </Box>
            )
            }


            <Dialog
                open={formDialog}
                onClose={() => setFormDialog(false)}
                PaperProps={{
                    component: 'form',
                }}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Upload Files</DialogTitle>
                <DialogContent>
                    {dataSaved && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                        Data Saved Succefully
                    </Alert>}
                    <Box sx={{marginBottom:2}}>

                        <FormControl>
                            <FormLabel id='demo-radio-buttons-group-label'>MBOL File</FormLabel>
                            <TextField
                                variant='outlined'
                                placeholder='Name'
                                onChange={handleMbolFile}
                                type='file'
                                inputProps={{
                                    multiple: false,
                                    accept:"application/pdf"
                                }}
                                
                            />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel id='demo-radio-buttons-group-label'>BOL Files</FormLabel>
                            <TextField
                                variant='outlined'
                                placeholder='Name'
                                onChange={handleBolFiles}
                                type='file'
                                inputProps={{
                                    multiple: true,
                                    accept:"application/pdf"
                                }}
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFormDialog(false)} sx={{fontSize:"13px"}} variant='outlined'>Cancel</Button>
                    <Button type="button" onClick={handleUploadMultiFiles} sx={{fontSize:"13px"}} variant='contained'>Submit</Button>
                </DialogActions>
            </Dialog>          

        </div>

    );
}
