import React, { useEffect } from 'react';
import { DataGridPremium, GridRowsProp, GridToolbar } from '@mui/x-data-grid-premium';
import { Box, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';



export default function OrderReportCarrier() {

    const [status, setStatus] = React.useState('All');
    const [statusData, setStatusData] = React.useState([])

    const isMobile = useMediaQuery('(max-width: 450px)')

    const [balanceData, setBalanceData] = React.useState([])
    const navigate = useNavigate();
    const currCustomer = localStorage.getItem("currentOwner")
    const currWHS = localStorage.getItem("currentWHS")
    
    const tokenValue: any = localStorage.getItem("accessToken")
    const apiUrlBal: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getRprt_carrierWiseShipmentDtls"


    async function getBalApiData() {
        const responseBal = await getDashData_balance(
            {
                "carrierCode":"all",
                "fromDate":"2023-01-01",
                "toDate":"2025-12-31",
                "custCode": currCustomer,
                "whseCode": currWHS
            })
        if (responseBal) {
            setBalanceData(responseBal.Payload)
        }
    }

    async function getDashData_balance(params: any) {
        return fetch(apiUrlBal, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    useEffect(() => {
        try {
            getBalApiData()
        }
        catch (e) {
            //log error
        }
    },
        []);

        const columns = [
           { field: 'CarrierCode',headerClassName:"headingbg-color", headerName: 'CarrierCode', width: 200 },
            { field: 'CarrierName',headerClassName:"headingbg-color", headerName: 'CarrierName', width: 200 },
            { field: 'ShipDate',headerClassName:"headingbg-color", headerName: 'ShipDate', width: 200},
            { field: 'CustPOnumber',headerClassName:"headingbg-color", headerName: 'CustPOnumber', width: 160 },
            { field: 'Customer',headerClassName:"headingbg-color", headerName: 'Customer', width: 150 },
    
            { field: 'TotalQty',headerClassName:"headingbg-color", headerName: 'TotalQty', width: 150 },
            { field: 'TotalWeight',headerClassName:"headingbg-color", headerName: 'TotalWeight', width: 150 },
            { field: 'TotalCube',headerClassName:"headingbg-color", headerName: 'TotalCube', width: 150 }
    
        ]
    
        const rows: GridRowsProp = balanceData.map((c: any,index) => ({
            id : index,
            Owner: c.Owner,
            WHSEID: c.WHSEID,
            CarrierCode: c.CarrierCode,
            CarrierName: c.CarrierName,
            ShipDate: c.ShipDate,
            CustPOnumber: c.CustPOnumber,
            Customer: c.Customer,
            TotalQty: c.TotalQty,
            TotalWeight: c.TotalWeight,
            TotalCube: c.TotalCube
        }));


    return (

        <div className='ml-2' style={{ height: '110vh' }}>
            <div className='mt-8 mb-6 text-center pageHeading'>Shipment Report Carrier wise</div>
            <div className='pb-2' style={{ width: '30%' }}>

            </div>
            {/* <p className='ml-2  mb-2 colordivSumHead'>Shipment Report Carrier wise :</p> */}
            <Box sx={{ width: '100%', height: 550, padding: '0 5%' }}>
            <DataGridPremium
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                    showQuickFilter: true,
                }
            }}
             
                columns={columns}
                rows={rows}
            ></DataGridPremium>
</Box>

        </div>
    );
}
